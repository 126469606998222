import styled from "styled-components"
import { Body2 } from "../Texts"

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  max-width: 336px;
  overflow: hidden;
  transition: all 250ms ease-out;

  && {
    text-decoration: none;
  }

  :hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  }

  > img,
  > .gatsby-image-wrapper {
    width: 100%;
  }
`

export const TextContainer = styled.div`
  padding: 32px 23px 40px 18px;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
`

export const Description = styled(Body2)`
  margin-top: 18px;
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`
