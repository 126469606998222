import React from "react"
import { BlogPostThumbnail } from "../../types/blogPost"
import * as S from "./styled"
import { DatoCmsHtmlText } from "../DatoCmsHtmlText"
import { useConfiguration } from "../../context/configuration"
import { parseDatoCmsPageLink } from "../DatoCmsAction"
import { DatoCmsAsset } from "../DatoCmsAsset"

export const BlogPostCard: React.FC<BlogPostThumbnail> = props => {
  const configuration = useConfiguration()
  const link = parseDatoCmsPageLink(props, configuration)
  return (
    <S.Container as={link.LinkComp} {...link.linkProps}>
      {props.thumbnailImage && <DatoCmsAsset {...props.thumbnailImage} />}
      <S.TextContainer>
        <S.Title>{props.title}</S.Title>
        <S.Description as={DatoCmsHtmlText} html={props.thumbnailDescription} />
      </S.TextContainer>
    </S.Container>
  )
}
