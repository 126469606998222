import styled from "styled-components"
import { GridCell, GridLayout } from "../../components/GridLayout"
import device from "../../components/Theme/devices"
import { SectionTitle } from "../../components/Texts"

export const Container = styled(GridLayout)``

export const TitleCell = styled(GridCell).attrs({
  row: 1,
  tabletRow: 1,
  column: "3 / 13",
  tabletCol: "2 / 8",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
  as: SectionTitle,
  forwardedAs: "div",
  centered: true,
})`
  margin-top: 60px;
  margin-bottom: 78px;
`

export const PostsContainer = styled(GridCell).attrs({
  row: 2,
  tabletRow: 2,
  column: "3 / 13",
  tabletCol: "2 / 8",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
})`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  @media (${device.tablet}) {
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 150px;
  }

  @media (${device.desktop}) {
    margin-left: -35px;
    margin-right: -35px;
    padding-bottom: 230px;
    :first-child {
      justify-content: start;
    }
    :not(:first-child) {
      justify-content: center;
    }
  }
  > * {
    flex: 100%;
    margin: 21px 0;

    @media (${device.tablet}) {
      margin: 21px 20px;
    }

    @media (${device.desktop}) {
      margin: 21px 35px;
    }
  }
`
