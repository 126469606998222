import React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import { PostHeader } from "../layout/PostHeader"
import { DatoCmsContent } from "../layout/DatoCmsContent"
import { BlogPost as IBlogPost } from "../types/blogPost"
import { BlogThumbnailSet } from "../layout/BlogThumbnailSet"
import { Favicon } from "../components/Favicon"

const BlogPost: React.FC<BlogPostData> = ({ data: { post } }) => (
  <article>
    <PostHeader
      image={post.headerImage}
      imageStyle={"plain"}
      showSocialMediaLinks={false}
      {...post}
    />
    {post.content.map((content, i) => (
      <DatoCmsContent
        //@ts-ignore
        key={content?.id}
        verticalMargins={"article"}
        before={i > 0 ? post.content[i - 1] : undefined}
        after={post.content[i + 1] || undefined}
        {...content}
      />
    ))}
    {post.relatedPosts && post.relatedPosts.length > 0 && (
      <BlogThumbnailSet
        title={post.relatedPostsLabel}
        posts={post.relatedPosts}
      />
    )}
    <HelmetDatoCms seo={post.seoMetaTags} />
    <Favicon />
  </article>
)

export default BlogPost

type BlogPostData = {
  data: {
    post: IBlogPost & { seoMetaTags: any }
  }
}

export const query = graphql`
  query($id: String) {
    post: datoCmsBlogPost(id: { eq: $id }) {
      ...BlogPost

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
