import React from "react"
import { BlogPostThumbnail } from "../../types/blogPost"
import * as S from "./styled"
import { BlogPostCard } from "../../components/BlogPostCard"

export const BlogThumbnailSet: React.FC<{
  posts: BlogPostThumbnail[]
  title?: string
  [other: string]: any
}> = props => (
  <S.Container {...props}>
    {props.title && <S.TitleCell>{props.title}</S.TitleCell>}
    <S.PostsContainer row={props.title ? 2 : 1} tabletRow={props.title ? 2 : 1}>
      {props.posts?.map(post => (
        <BlogPostCard key={post.id} {...post} />
      ))}
    </S.PostsContainer>
  </S.Container>
)
